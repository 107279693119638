import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

export default function Layout(props) {
  return (
    <main style={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100vw' }}>
      <Navbar />
      <div style={{ flexGrow: 1 }}>
        {props.children}
      </div>
      <Footer />
    </main>
  )
}