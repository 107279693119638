import React from "react";
import { NavLink } from "react-router-dom";

const About = () => {
  return (
    <div id="header" className="container">
      <div className="mb-5">
        <h1>About <strong className="brand-name"> CoreX Connect </strong></h1>
        <h2 className="my-3">
          CoreX Connect is a comprehensive HR and employee management solution designed to streamline and enhance the workplace experience.Our platform combines essential tools for attendance tracking, leave management, work - from - home applications, and real - time reporting, making it easy for companies to manage their workforce efficiently.With a user - friendly, one - page design, CoreX Connect offers instant access to all functionalities, ensuring a seamless and productive experience for employees and managers alike.

          We are committed to helping organizations of all sizes simplify their HR processes, improve accountability, and foster a more connected, engaged workplace.From intuitive time tracking to customized QR codes for attendance and robust analytics, CoreX Connect provides a complete suite of tools tailored to meet the demands of modern businesses.
        </h2>
        <div className="mt-3">
          <NavLink to='/contact' className="btn-get-started">
            Contact Now
          </NavLink>
        </div>
      </div>
      <div>
        <h1>Our <strong className="brand-name"> Team </strong></h1>
        <div className="row mt-5">
          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
            <img
              src='/Rishan.jpg'
              style={{ borderRadius: '50%', objectFit: 'cover', width: '200px', height: '200px' }} className="img-fluid" alt="Rishan"
            />
            <h2 className="my-3">Rishan Mohommed</h2>
            <p>Founder</p>
          </div>
          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
            <img
              src='/Minindu.jpeg'
              style={{ borderRadius: '50%', objectFit: 'cover', width: '200px', height: '200px' }} className="img-fluid" alt="Minindu"
            />
            <h2 className="my-3">Minindu Senadheera</h2>
            <p>Co Founder</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
