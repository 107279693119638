import React from "react";

const Footer = () => {
    return (
        <footer className="footer w-100 bg-light text-center ">
            <p> 2025 All Rights Reserved | Terms and Conditions</p>
        </footer>
    )
};

export default Footer;
