import React from "react";

const Service = () => {
  const services = [
    {
      title: "Attendance Tracking",
      points: [
        "Clock in/out and record work hours.",
        "View attendance history and reports."
      ]
    },
    {
      title: "Leave Management",
      points: [
        "Submit leave applications.",
        "Check leave balance and history.",
        "Approvals and notifications."
      ]
    },
    {
      title: "Work from Home(WFH) Application",
      points: [
        "Apply for WFH days.",
        "Track WFH approvals."
      ]
    },
    {
      title: "Notice Board",
      points: [
        "View company announcements and important notices."
      ]
    },
    {
      title: "Report Generation",
      points: [
        "Generate attendance and work reports.",
        "Download or share reports."
      ]
    },
    {
      title: "Employee Profiles",
      points: [
        "Maintain employee details and job information."
      ]
    },
    {
      title: "Time Tracker",
      points: [
        "Track specific project or task hours."
      ]
    },
    {
      title: "QR Code Generator",
      points: [
        "Create individual QR codes for attendance or access control."
      ]
    },
    {
      title: "Meeting Scheduler",
      points: [
        "Schedule meetings with video call integration(similar to Google Meet)."
      ]
    },
    {
      title: "Dashboard Analytics",
      points: [
        "Visualize attendance trends, work hours, and other key metrics."
      ]
    }
  ]

  return (
    <div className="container">
      <h1 className="text-center">Our Services</h1>
      <div className="row">
        {services.map((service, index) =>
          <div className="col-md-4 g-3">
            <div className="card" key={index}>
              <div className="card-body">
                <h5 className="brand-name">{service.title}</h5>
                <ul>
                  {service.points.map((point, index) => (
                    <li key={index}>{point}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Service;